import styles from './assignees.module.css'
import {Link} from '@primer/react'
import {hovercardAttributesForActor} from '@github-ui/hovercards'
import {VALUES} from '../constants/values'

type AssignmentEventAssigneeProps = {
  assignee?: {
    __typename: string
    login: string
    resourcePath: string
    isCopilot?: boolean
  } | null
}

export function AssignmentEventAssignee({assignee}: AssignmentEventAssigneeProps): JSX.Element {
  const assigneeLogin = assignee?.login || VALUES.ghost.login
  const isCopilot = assignee?.isCopilot
  const isUser = assignee?.__typename === 'User' || assignee?.__typename === 'Organization'
  const showHovercard = isUser || isCopilot
  const hovercardData = showHovercard ? hovercardAttributesForActor(assigneeLogin, {isCopilot: assignee.isCopilot}) : {}
  const displayName = isCopilot ? VALUES.copilot.displayName : assigneeLogin || VALUES.ghost.login
  return (
    <Link {...hovercardData} href={assignee?.resourcePath} className={styles.assigneeLink} inline>
      {displayName}
    </Link>
  )
}

try{ AssignmentEventAssignee.displayName ||= 'AssignmentEventAssignee' } catch {}